.top_c {
  padding: 0.175rem;

  .c_m {
    font-size: .175rem;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: aqua;
    color: #000;
    display: block;

    position: relative;
    
    .anticon-close {
      position: absolute;
      top: -5px;
      right: -5px;
      color: red;
      cursor: pointer;
    }
  }
}
