.left-nav {
    // overflow: auto;
    // height: 100%;
    .title-icon{
        margin-right: 6px;
    }
    .left-nav-link {
        display: flex;
        width: 100%;
        height: 90px;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        margin-top: 25px;
        margin-bottom: 10px;

        img {
            width: 97px;
            height: 32px;
        }
        h1 {
            margin-bottom: 0;
            font-family: Helvetica;
            font-size: 20px;
            color: #FFFFFF;
            letter-spacing: 0;
            font-weight: 400;
        }
    }
}
