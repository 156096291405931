.h_container {
    padding   : 0px;
    height    : 56px;
    width     : 100%;
    z-index   : 19;
    display   : flex;
    background: #fff;
    border-top: 1px solid rgba(0 21 41 / 8%);
    // box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    box-shadow:  0 3px 8px 0 rgba(0 21 41 / 8%);

    .h_body {
        width  : 100%;
        padding: 0 30px;
        .header_city {
            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                padding: 0px;
            }
            .ant-select-selection-item {
                color: #6e6e6e;
                font-weight: 500;
            }
        }
        .header_user {
            .h_user_image {
                margin-right: 20px;

                img {
                    height       : 36px;
                    width        : 36px;
                    border-radius: 50%;
                }
            }

            .h_info {
                margin-right: 20px;

                div {
                    font-family   : Helvetica;
                    font-size     : 14px;
                    color         : rgba(0, 0, 0, 0.65);
                    letter-spacing: 0;
                    line-height   : 22px;
                    font-weight   : 400;
                }
            }

            .h_btn {
                i {
                    font-size: 24px;
                }
            }
        }

    }

}