// @import '~antd/dist/antd.css';

body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
        apple color emoji, segoe ui emoji, Segoe UI Symbol, noto color emoji;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    min-width: 1300px;
    height: 100%;
    width: 100%;
    font-family: PingFangSC-Regular, PingFang SC;
    // overflow-y: scroll;
    // background-color: #02041d;
}
p {
    margin-bottom: 0;
}

.ant-menu-inline {
    border-right: none;
}
.ant-menu-submenu:first-of-type {
    margin-top: 4px;
}
.ant-menu-submenu-title {
    margin-top: 0;
}
// @media screen and (max-width: 24rem) {
//     html {
//         font-size: 0.2rem;
//     }
// }
// @media screen and (max-width: 23.5rem) {
//     html {
//         font-size: 0.1875rem;
//     }
// }
// @media screen and (max-width: 22.25rem) {
//     html {
//         font-size: 0.175rem;
//     }
// }
// @media screen and (max-width: 21rem) {
//     html {
//         font-size: 0.1625rem;
//     }
// }
// @media screen and (max-width: 19.75rem) {
//     html {
//         font-size: 0.15rem;
//     }
// }
// @media screen and (max-width: 17.25rem) {
//     html {
//         font-size: 0.125rem;
//     }
// }
// @media screen and (max-width: 13.5rem) {
//     html {
//         font-size: 0.1rem;
//     }
// }

//公共样式
.mr-6 {
    margin-right: 0.075rem;
}
.flex {
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.flex-s-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
}
.flex-s-b-w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.flex-s-b-g {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.flex-s-a {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-start-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-column-s {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.flex-column-s-b {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.flex-column-s-a {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.flex-column-end {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
}
.flex-1 {
    flex: 1;
}
.f_s1 {
    font-size: 42px;
    color: #01ffff;
    letter-spacing: 0.0291rem;
    font-weight: 600;
}
.f_s2 {
    font-size: 0.75rem;
    color: #01ffff;
    letter-spacing: 0.0416rem;
    font-weight: 600;
}

.e_file {
    .e_file_name {
        font-family: PingFangSC-Regular;
        font-size: 0.175rem;
        color: #1890ff;
        letter-spacing: 0.0097rem;
        font-weight: 400;
        display: inline-block;
        width: 3.1875rem;
        overflow: hidden;
    }
    .e_file_btn {
        font-family: PingFangSC-Semibold;
        font-size: 14px;
        color: red;
        letter-spacing: 1px;
        font-weight: 600;
        cursor: pointer;
    }
}

//定义滚动条样式
::-webkit-scrollbar {
    width: 4px;
    height: 20px;
}
//定义滚动条轨道内阴影+圆角
::-webkit-scrollbar-track {
    box-shadow: inset0remrgba(240, 240, 240, 0.5);
    border-radius: 0.125rem;
    background-color: transparent;
}
//定义滚动条滑块
::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    box-shadow: inset0remrgba(240, 240, 240, 0.5);
    background-color: rgba(12, 11, 11, 0.5);
}

.custom_table {
    padding: 0.2rem;
    .custom_pagination {
        height: 60px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
    }
}
.seach_container {
    padding: 0.125rem 0.175rem 0 0.175rem;
    .seach_query {
        width: 100%;
        // height: 0.8875rem;
        // padding: 0 0.225rem;
        padding: 0.225rem;
        background: #fff;
    }
    .seach_query_box {
        width: 100%;
        padding: 0 0.225rem;
        background: #fff;

        .seach_query_components {
            padding: 0.225rem 0;
        }
    }
    .seach_query_div {
        padding: 0 0.3125rem;
    }
}
.a_class {
    color: #1890ff !important;
    cursor: pointer;
}

//字体大小
.f-s-12 {
    font-size: 0.15rem;
}
.f-s-14 {
    font-size: 0.175rem;
}

//字体粗细
.f-s-500 {
    font-weight: 500;
}

//手指
.c_pointer {
    cursor: pointer;
}
// margin
.m-t-10 {
    margin-top: 0.125rem;
}