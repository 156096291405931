.breadcrumb_container {
    background-color: #fff;
    margin: .1667rem .175rem 0 .175rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: .1667rem;
    .top-breadcrumb {
        
        padding: 10px;
        font-size: 0.175rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
