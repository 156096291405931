.l_bg {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: #015caf;
}
.l_body {
    z-index: 99;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 17.4875rem;
    height: 9.6375rem;
    border-radius: 0.625rem;
    overflow: hidden;
}
.l_title {
    height: 9.6375rem;
    width: 9.975rem;
    padding: 50px 30px;
    background: linear-gradient(195.71deg, rgba(1, 130, 246, 1) 0%, rgba(0, 110, 238, 1) 100%);
    img {
        width: 700px;
    }
    .l_l_title {
        height: 180px;
        p {
            font-size: 0.5rem;
            font-weight: 600;
            letter-spacing: 0px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
        }
    }
}
.login {
    width: 7.5125rem;
    height: 100%;
    padding: 30px;
    background: #026dd4;
    .login_content {
        width: 5.25rem;
        .login_title {
            p {
                font-size: 0.5rem;
                font-weight: 600;
                letter-spacing: 0px;
                color: rgba(255, 255, 255, 1);
                text-align: left;
                vertical-align: middle;
            }
            width: 100%;
            .login_line {
                width: 151px;
                height: 48px;
                border-bottom: 2px solid #5dcffa;
            }
        }
        .login_form {
            margin-top: 0.475rem;
            p {
                font-size: 0.325rem;
                font-weight: 500;
                letter-spacing: 0px;
                color: rgba(255, 255, 255, 1);
                text-align: left;
                vertical-align: middle;
            }
            .l_prefix_p {
                width: 0.175rem;
                height: 0.2rem;
            }
            .l_prefix_m {
                width: 16px;
                height: 12px;
            }
            .ant-input-affix-wrapper-lg {
                padding: 0px 11px;
            }
            .ant-input-affix-wrapper {
                background-color: transparent;
                border: none;
                border-radius: 5px;
                height: 50px;
            }
            .ant-form-item {
                margin-bottom: 16px;
            }
            .login_form_button {
                width: 100%;
                height: 0.65rem;
                background-image: linear-gradient(90deg, #029af9 0%, #3db4fb 100%);
                border-radius: 6px;
                margin-top: 30px;
                font-weight: 500;
                font-size: 0.3rem;
                color: #fff;
                letter-spacing: 0.784px;
                font-weight: 400;
                border: none !important;
            }
            .l_message_box {
                display: flex;
                justify-content: space-between;
                background: #fff;
                border-radius: 6px;
                overflow: hidden;
                height: 0.6rem;
                line-height: 0.6rem;
                margin-top: 0.125rem;
                .l_message_input {
                    width: 2.875rem;
                }
                .login_getPwd {
                    font-size: 0.225rem;
                    color: #999;
                    pointer-events: none;
                }
                .login_btn_box {
                    display: inline-block;
                    height: 0.6rem;
                    line-height: 0.6rem;
                    text-align: right;
                    margin-right: 18px;
                }
                .login_can {
                    font-size: 0.225rem;
                    color: #005caf;
                    cursor: pointer;
                }
            }

            input {
                font-size: 0.225rem !important;
                border: none !important;
            }
            .ant-input {
                color: #005caf;
            }
            .ant-input:focus {
                border: none;
                border-bottom: none !important;
                box-shadow: none !important;
            }
            .ant-input-suffix .ant-input-clear-icon {
                font-size: 16px;
            }
        }
    }
}
